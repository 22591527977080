import { ITriggerToIds } from "../@types/trigger-to-ids";

const getMainScene = (vertical: boolean, triggerToIds: ITriggerToIds) => {
    return `
<a-scene
    xrextras-gesture-detector
    xrextras-almost-there
    xrextras-loading
    xrextras-runtime-error
    xrweb="disableWorldTracking: true"
    renderer="colorManagement: true"
    id='scene'
>
    <a-assets>
        <a-asset-item id="flat-plane" src="assets/general-assets/flat-plane.glb"></a-asset-item>
    </a-assets>


    <xrextras-capture-button capture-mode="fixed"></xrextras-capture-button>

    <xrextras-capture-config
        max-duration-ms="5000"
        max-dimension="1280"
        enable-end-card="true"
        cover-image-url=""
        end-card-call-to-action=""
        short-link=""
        footer-image-url=""
        watermark-image-url=""
        watermark-max-width="100"
        watermark-max-height="10"
        watermark-location="bottomRight"
        file-name-prefix="screen-recording-"
        request-mic="manual"
        include-scene-audio="false"
        ></xrextras-capture-config>

        <xrextras-capture-preview
        action-button-share-text="Share"
        action-button-view-text="View"
        finalize-text="Exporting..."
    ></xrextras-capture-preview>

    <a-camera
        position="0 1.5 1.5"
        raycaster="objects: .cantap"
        cursor="fuse: false; rayOrigin: mouse;"
        id='camera'
    >
    </a-camera>
    <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>
    <a-light type="ambient" intensity="1"></a-light>
    ` + getTriggerElements(vertical, triggerToIds);

    //     <!-- Note: "name:" must be set to the name of the image target uploaded to the 8th Wall Console -->
    //     <xrextras-named-image-target name="clarkee-colouring-sheet" id='clarkee-colouring-sheet'>
    //         <a-entity
    //             id="image-tracking-plane-clarkee"
    //             gltf-model="#flat-plane"
    //         ></a-entity>
    //         <a-entity id="clarkee-parent-ele">
    //             <a-entity
    //                 animation-mixer
    //                 rotation="${vertical ? '0' : '90'} 0 0"
    //                 scale='0.4 0.4 0.4'
    //                 id="render-texture-model-clarkee"
    //                 model-loading-component="loaded: false; gltf: assets/clarkee.glb"
    //             ></a-entity>
    //         </a-entity>
    //     </xrextras-named-image-target>
    // </a-scene>
    // `;
}

const getTriggerElements = (vertical: boolean, triggerToIds: ITriggerToIds) => {
    let output = '';

    for (let [key, value] of Object.entries(triggerToIds)) {
        // as double quotes conflict between the json syntax and the aframe component syntax
        // the double quotes in the json are converted to single quotes after being converted to a string
        const stringAnimation = JSON.stringify(value.animations).replaceAll('"', "'");
        const animationComponent = `animation-controller-component="animationsString: ${stringAnimation}"`

        output += `
            <!-- Note: "name:" must be set to the name of the image target uploaded to the 8th Wall Console -->
            <xrextras-named-image-target name="${key}" id='${key}'>
                <a-entity
                    id="image-tracking-plane-${value.id}"
                    gltf-model="#flat-plane"
                ></a-entity>
                <a-entity id="parent-ele-${value.id}">
                    <a-entity
                        animation-mixer
                        rotation="${vertical ? '0' : '90'} 0 0"
                        scale='0.4 0.4 0.4'
                        id="render-texture-model-${value.id}"
                        model-loading-component="loaded: false; gltf: ${value.filePath}"
                        ${value.animations.length > 0 ? animationComponent: ''}
                    ></a-entity>
                </a-entity>
            </xrextras-named-image-target>
        </a-scene>
        `;
    }

    return output
}


export { getMainScene }